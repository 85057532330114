import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

import dayjs from 'dayjs';

const STORAGE_KEY = 'try-regate-modal-last-shown';

export function getLastShownTimestamp(): dayjs.Dayjs | null {
  const timestamp = safeLocalStorage.getItem(STORAGE_KEY);
  return timestamp ? dayjs(timestamp) : null;
}

export function setLastShownTimestamp(): void {
  const now = dayjs().toISOString();
  safeLocalStorage.setItem(STORAGE_KEY, now);
}

export function shouldShowModal(): boolean {
  const lastShownTimestamp = getLastShownTimestamp();
  if (!lastShownTimestamp) return true;
  const hoursSinceLastShown = dayjs().diff(lastShownTimestamp, 'hour');
  return hoursSinceLastShown >= 72;
}
